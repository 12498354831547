<template>
    <v-container fluid class="pa-0">
        <filtros :loading="loading" 
            :filters="filters" 
            @update-filters="updateFilters" 
        />

        <v-row v-if="filteredPoints.length <= 0 && !loading" class="px-5">
            <v-col>
                <i>Nenhum ponto de captura encontrado </i>
            </v-col>
        </v-row>

        <v-card class="mx-4 my-5 p-4" v-for="ponto in filteredPoints" :key="ponto.id">
            <h4>#{{ponto.id}} - {{ponto.descricao}}</h4>

            <v-row class="d-flex justify-center mt-4 mb-2" v-if="ponto.cameras.length">
                <v-card
                    elevation="2"
                    class="m-2 px-4 py-2"
                    v-for="camera in ponto.cameras"
                    :key="camera.id"
                >
                    <v-card-title class="text-h4">
                        <b>#{{ camera.id }} - {{ camera.descricao }}</b>
                    </v-card-title>

                    <v-card-text>
                        <div class="mb-4">
                            <div>
                                <b>Quantidade de capturas</b>
                                <p>{{ camera.qtde_capturas_hoje }}</p>
                            </div>
                        </div>

                        <div class="mb-4">
                            <b>Última captura</b>
                            <p>{{ formatDate(camera.data_ultima_captura) }}</p>
                        </div>

                        <div class="mb-4" v-if="camera.status == status_situacao_inativa">
                            <b>Status</b>
                            <p class="text-danger font-weight-medium mb-4">mais de 30min sem dados novos</p>
                        </div>

                        <div class="mb-4" v-else-if="camera.status == status_situacao_ativa">
                            <b>Status</b>
                            <p class="text-success font-weight-medium mb-4">camera ok</p>
                        </div>  

                        <div class="mb-4" v-else-if="camera.status == status_situacao_atencao">
                            <b>Status</b>
                            <p class="text-warning font-weight-medium mb-4">sem dados novos a menos de 30min</p>
                        </div> 
                    </v-card-text>
                </v-card>
           </v-row>
        </v-card>
    </v-container>
</template>

<script>
import apiPontosCaptura from '@/api/pontos-captura'
import Filtros from './Filtros'
import dayjs from 'dayjs'

export default {
    data() {
        return {
            pontos: [],
            loading: false, 
            page: 1,
            status_situacao_inativa: 0,
            status_situacao_ativa: 1,
            status_situacao_atencao: 2,
            filters: {
                ponto: null,
                cam: null,
                status: null
            }
        }
    },

    components: {
        Filtros
    },

    created() {
        this.callLoadCams()
    },

    methods: {
        async callLoadCams() {
            try {
                this.loading = true

                const resposta = await apiPontosCaptura.camerasPorPontoCaptura()
                this.pontos = resposta.data.pontos_captura
            } catch (e) {
                this.$snackbar.mostrar({ cor: 'warning', mensagem: 'Erro ao buscar os pontos de captura' })
            } finally {
                this.loading = false
            }
        },

        updateFilters(filters) {
            this.filters = filters
        },

        formatDate(date) {
            return dayjs(date).format('DD/MM/YYYY - HH:mm:ss')
        }
    },

    computed: {
        filteredPoints(){
            let filteredPoints = [ ...this.pontos ]

            if (this.filters.ponto) {
                filteredPoints = filteredPoints.filter(ponto => {
                    return ponto.descricao.toLowerCase().indexOf(this.filters.ponto.toLowerCase()) >= 0
                })
            }

            if (this.filters.cam) {
                filteredPoints = filteredPoints.filter(ponto => {
                    let cameras = ponto.cameras.filter(camera => {
                        return camera.descricao.toLowerCase().indexOf(this.filters.cam.toLowerCase()) >= 0
                    })

                    return cameras.length
                })
            }

            if (this.filters.status !== null) {
                let status = false
                
                switch (this.filters.status) {
                    case "inativo":
                        status = 0
                        break;
                    case "ativo":
                        status = 1
                        break;
                    case "atencao":
                        status = 2
                        break;
                    default:
                        return
                }

                filteredPoints = filteredPoints.filter(ponto => {
                    let cameras = ponto.cameras.filter(camera => {
                        return camera.status == status
                    })

                    return cameras.length
                })
            }

            return filteredPoints
        }
    }
}
</script>