<template>
  <v-sheet
    color="white"
    class="mb-5"
    elevation="1"
    @keyup.enter="$emit('buscar')"
  >
    <v-container fluid>
      <v-row class="pa-3 d-flex justify-center">
        <v-col
          cols="6"
          sm="6"
          md="6"
          lg="2"
          xl="2"
          class="pa-2 pa-lg-1"
          order="5"
        >
          <v-text-field
            :v-model="filters.ponto"
            outlined
            dense
            autocomplete="off"
            hide-details="auto"
            clearable
            label="Ponto de captura"
            @input="updateFilters('ponto', $event)"
          />
        </v-col>

        <v-col
          cols="6"
          sm="6"
          md="6"
          lg="2"
          xl="2"
          class="pa-2 pa-lg-1"
          order="5"
        >
          <v-text-field
            :v-model="filters.cam"
            outlined
            dense
            autocomplete="off"
            hide-details="auto"
            clearable
            label="Câmera"
            @input="updateFilters('cam', $event)"
          />
        </v-col>

        <v-col
          cols="6"
          sm="6"
          md="6"
          lg="2"
          xl="2"
          class="pa-2 pa-lg-1"
          order="7"
        >
          <v-select
            :v-model="filters.status"
            :items="statusOptions"
            item-text="title"
            item-value="value"
            label="Status da câmera"
            hide-details="auto"
            clearable
            outlined
            dense
            @change="updateFilters('status', $event)"
          />
        </v-col>
      </v-row>
    </v-container>
    <v-progress-linear
      v-if="loading"
      color="primary"
      absolute
      indeterminate
    />
  </v-sheet>
</template>

<script>
export default {
  props: {
    startfilters: {
      type: Object,
      default: () => {
        return {};
      },
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      statusOptions: [
        { title: 'Inativo', value: "inativo" },
        { title: 'Ativo', value: "ativo" },
        { title: 'Atenção', value: "atencao" }
      ],
      filters: {
        ponto: null,
        cam: null,
        status: null
      }
    }
  },

  methods: {
    updateFilters(key, value) {
      this.filters[key] = value
      
      this.$emit("update-filters", this.filters)
    },

    cleanFilterVars() {
      this.filters = {
        ponto: null,
        cam: null,
        status: null
      }

      this.$emit("update-filters", this.filters)
    },

    created() {
      this.filters = this.startfilters
    }
  },


}
</script>

<style scoped>
.inputPrice >>> input[type="number"] {
  -moz-appearance: textfield;
}
.inputPrice >>> input::-webkit-outer-spin-button,
.inputPrice >>> input::-webkit-inner-spin-button {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}
.placa {
  position: relative;
}
pre {
  font-family: system-ui;
  margin: 0;
}
.placasModal {
  position: absolute;
  background-color: #d7d7d7;
  height: 255px;
  left: -50%;
  top: 45px;
  padding: 1em;
  -webkit-transition: all 0.8s;
  transition: all 0.8s;
  color: #363636;
  border-radius: 5px;
  z-index: 500;
}
.linha1,
.linha3,
.linha6,
.linha7,
.linha8 {
  background-color: white;
}
.placasModal pre span {
  color: red;
}
.placasModal pre {
  font-size: 1em;
  padding: 0.3em;
}
.linha1 {
  color: white;
  background-color: #363636;
}
.linha4,
.linha5,
.linha6,
.linha7,
.linha8 {
  padding: 0 !important;
}
.linha4,
.linha6 {
  padding-top: 0.3em !important;
  padding-left: 0.3em !important;
  padding-right: 0.3em !important;
}
.linha5,
.linha8,
.linha9 {
  padding-bottom: 0.3em !important;
  padding-left: 0.3em !important;
  padding-right: 0.3em !important;
}
@media (max-width: 959px) {
  .buscar {
    justify-content: center;
  }
  .placasModal {
    display: none;
  }
}
@media (max-width: 1264) {
  .placasModal {
    left: -20%;
    top: 50
  }
}
@media (min-width: 1264px) and (max-width: 1400px) {
  .buscar-btn {
    width: 80px !important;
  }
  .botao-icone {
    margin: 0 !important;
  }
}
</style>
